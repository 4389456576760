var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('h3',[_vm._v("ตั้งค่าการแจ้งเตือน")]),_c('b-row',{staticClass:"mt-2",attrs:{"cols":"1","cols-md":"2"}},[_c('b-col',[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-card',[_c('b-card-title',[_vm._v("แจ้งเตือนรูปภาพถูกสร้าง")]),_c('validation-observer',{ref:"createFormRef"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"หัวข้อ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"หัวข้อ"},model:{value:(_vm.formCreate.title),callback:function ($$v) {_vm.$set(_vm.formCreate, "title", $$v)},expression:"formCreate.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"รายละเอียด","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"รายละเอียด"},model:{value:(_vm.formCreate.body),callback:function ($$v) {_vm.$set(_vm.formCreate, "body", $$v)},expression:"formCreate.body"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm('createFormRef')}}},[_vm._v(" บันทึก ")])],1)],1)],1)],1)],1)],1)],1),_c('b-col',[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-card',[_c('b-card-title',[_vm._v("แจ้งเตือนรูปภาพถูกอัพเดท")]),_c('validation-observer',{ref:"updateFormRef"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"หัวข้อ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"หัวข้อ"},model:{value:(_vm.formUpdate.title),callback:function ($$v) {_vm.$set(_vm.formUpdate, "title", $$v)},expression:"formUpdate.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"รายละเอียด","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"รายละเอียด"},model:{value:(_vm.formUpdate.body),callback:function ($$v) {_vm.$set(_vm.formUpdate, "body", $$v)},expression:"formUpdate.body"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm('updateFormRef')}}},[_vm._v(" บันทึก ")])],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }