<template>
  <div class="">
    <h3>ตั้งค่าการแจ้งเตือน</h3>
    <b-row cols="1" cols-md="2" class="mt-2">
      <b-col>
        <b-overlay :show="loading">
          <b-card>
            <b-card-title>แจ้งเตือนรูปภาพถูกสร้าง</b-card-title>
            <validation-observer ref="createFormRef">
              <b-form>
                <b-row>
                  <b-col sm="12">
                    <b-form-group>
                      <validation-provider #default="{ errors }" name="หัวข้อ" rules="required">
                        <b-form-input
                          v-model="formCreate.title"
                          :state="errors.length > 0 ? false : null"
                          placeholder="หัวข้อ"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group>
                      <validation-provider #default="{ errors }" name="รายละเอียด" rules="required">
                        <b-form-input
                          v-model="formCreate.body"
                          :state="errors.length > 0 ? false : null"
                          placeholder="รายละเอียด"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-button variant="primary" type="submit" @click.prevent="validationForm('createFormRef')">
                      บันทึก
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
      <b-col>
        <b-overlay :show="loading">
          <b-card>
            <b-card-title>แจ้งเตือนรูปภาพถูกอัพเดท</b-card-title>
            <validation-observer ref="updateFormRef">
              <b-form>
                <b-row>
                  <b-col sm="12">
                    <b-form-group>
                      <validation-provider #default="{ errors }" name="หัวข้อ" rules="required">
                        <b-form-input
                          v-model="formUpdate.title"
                          :state="errors.length > 0 ? false : null"
                          placeholder="หัวข้อ"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="12">
                    <b-form-group>
                      <validation-provider #default="{ errors }" name="รายละเอียด" rules="required">
                        <b-form-input
                          v-model="formUpdate.body"
                          :state="errors.length > 0 ? false : null"
                          placeholder="รายละเอียด"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-button variant="primary" type="submit" @click.prevent="validationForm('updateFormRef')">
                      บันทึก
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BButton, BOverlay } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    ToastificationContent,
  },
  data() {
    return {
      required,
      loading: false,
      formUpdate: {
        body: '',
        title: '',
      },
      formCreate: {
        body: '',
        title: '',
      },
      oldData: null,
    }
  },
  methods: {
    async validationForm(refName) {
      // console.log('refName', refName)
      this.loading = true
      const validate = await this.$refs[refName].validate()
      if (!validate) return ''

      const requestData = this.oldData

      if (refName === 'createFormRef') {
        requestData.create = this.formCreate
        if (this.formUpdate.body !== '' && this.formUpdate.title !== '') {
          requestData.update = this.formUpdate
        }
      } else {
        requestData.update = this.formUpdate
        if (this.formCreate.body !== '' && this.formCreate.title !== '') {
          requestData.create = this.formCreate
        }
      }

      const res = await this.api.post('api/admin/notification-data-file/update-file', requestData)
      // console.log('res', res)
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'อัพเดทการแจ้งเตือนสำเร็จแล้ว',
          icon: 'BellIcon',
          variant: 'success',
        },
      })
      this.loading = false
      return ''
    },
  },
  async created() {
    this.loading = true
    const res = await this.api.get('api/admin/notification-data-file/index')
    // console.log('res', res)
    this.oldData = JSON.parse(JSON.stringify(res))
    this.formUpdate = res.update
    this.formCreate = res.create
    this.loading = false
  },
}
</script>
